import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "../css/Slidder.css"

const CarouselImage = ({img}) => {
    return(
        <>
            <img className='w-100' src={img} />
        </>
    )
}

const Slidder = () => {
  return (
    <div className='slidder'>
        <Carousel>
            <Carousel.Item interval={1000}>
                <CarouselImage img="/images/img2.jpeg" />
                <Carousel.Caption>
                <h3>Powerfull Spells</h3>
                <p>Welcome to the realm of powerful spells, where magic meets destiny. Unleash your potential with mystical incantations. Embrace the extraordinary, wield the enchantment within.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
                <CarouselImage img="/images/img1.avif" />
                <Carousel.Caption>
                <h3>Magic Charms</h3>
                <p>Welcome to Magic Charms, where dreams meet enchantment! Uncover wonders, embrace mystique, and embark on a journey beyond imagination. Experience the allure of our mystical treasures today!</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  )
}

export default Slidder