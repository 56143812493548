import React from 'react'
import "../css/Body.css"
import { Link } from 'react-router-dom'

const Body = () => {
  return (
    <>
       <div className='body'>
            <div class="main_heading text-center">
                <h2 className='text-center'>Find Your Way to happiness through the most powerful spell caster</h2>
            </div>
            <p>Within the realms of our mystical sanctuary lies the key to unlocking boundless joy and unwavering contentment. Each enchantment crafted by our revered masters holds the promise of guiding you towards a life filled with profound happiness.</p>
            <p className='d-none'>Delve into our ancient grimoires, where whispered secrets and ancient incantations converge, offering a tapestry of potent spells designed to illuminate the path to true bliss. From love charms that weave connections as eternal as the stars, to prosperity spells that beckon abundance into your existence, our offerings span the spectrum of desires, catering to every soul's quest for happiness.</p>
            <p>Amidst the flickering candlelight and fragrant herbs, our adept sorcerers channel the energies of the universe, infusing each spell with an infusion of hope, positivity, and unyielding optimism. Trust in their wisdom as they craft bespoke enchantments, meticulously tailored to align with your deepest aspirations, paving the way to a life adorned with smiles and serenity.</p>
            <p>Step into the embrace of our enchanted gardens, where mystical energies dance among the flora and fauna. Feel the whispers of nature's wisdom guiding you towards the fulfillment of your heart's most cherished desires. Here, the very essence of happiness intertwines with the fabric of existence, waiting to be unveiled by those seeking its transformative power.</p>
            <p className='d-none'>Unearth the ancient wisdom passed down through generations, ensconced within the sacred scrolls and mystical artifacts that adorn our hallowed halls. These artifacts hold the essence of forgotten lore, ready to unfurl the secrets that lead to the happiness you seek.</p>
            <p>Embrace the power of intention and manifestation as you harness the energies surrounding you. Our spells serve as conduits, channeling your desires into the universe's cosmic fabric, ensuring that your quest for happiness resonates throughout time and space.</p>
            <p>Through our sacred rites and invocations, transcend the mundane and traverse realms where dreams take flight and happiness becomes an unwavering companion. Trust in the unseen forces that converge to usher in a life brimming with the purest form of joy.</p>
        </div>
        <div class="main_heading text-center">
            <h2>Why Choose Us</h2>
            <p class="large text-center">Fastest services with best price!</p>
        </div>
        <div className='d-block d-md-flex gap-4 charms bg-danger'>
            <div className='flex-grow-1'>
                <h3>Powerfull Love & Money</h3>
                <h2 className='text-white'>Spells</h2>
                <h4 className='text-white'>100% Guarantee</h4>
                <img className='w-100 mb-3' src='/images/Love-charms.jpg' />
            </div>
            <div className='flex-grow-1'>
                <img className='w-100' src='/images/stand_5757.jpg' />
                <p>Delve into our ancient grimoires, where whispered secrets and ancient incantations converge, offering a tapestry of potent spells designed to illuminate the path to true bliss.</p>
            </div>
            <div className='flex-grow-1'>
                <p>From love charms that weave connections as eternal as the stars, to prosperity spells that beckon abundance into your existence, our offerings span the spectrum of desires, catering to every soul's quest for happiness.</p>
                <img className='w-100' src='/images/witch.jpeg' />
            </div>
        </div>
        <div className='spels-charms'>
            <h2>Looking for a powerful spell</h2>
            <p className='wd-75 text-secondary'>Dare to believe in the allure of magic; let its essence infuse every facet of your being. Embrace the enchantments, heed the guidance, and embark on a transformative journey towards an existence illuminated by the radiance of true happiness."</p>
            <div className='d-flex charmed flex-wrap gap-4'>
                <div className='flex-grow-1'>
                    <h3>Love Spells</h3>
                    <p>Are you out of each other’s lives, need to stop a divorce or want marriage? You can get you lost lover back by using my powerful Love Spells</p>
                    <Link to={"/spells/love-spell"} className='btn btn-sm btn-danger rounded-pill mb-2'>Read More ..</Link>
                </div>
                <div className='flex-grow-1'>
                    <h3>Money Spells</h3>
                    <p>Do you need a spell to attract money, more customers or u are you about to lose your property? Get back your financial freedom using my powerful money spells.</p>
                    <Link to={"/spells/money-spell"} className='btn btn-sm btn-danger rounded-pill mb-2'>Read More ..</Link>
                </div>
                <div className='flex-grow-1'>
                    <h3>Beauty Spells</h3>
                    <p>Do you want to feel like a goddess, command how attractive you are to the other gender? My beauty spells help in enhancing natural beauty.</p>
                    <Link className='btn btn-sm btn-danger rounded-pill mb-2'>Read More ..</Link>
                </div>
                <div className='flex-grow-1'>
                    <h3>Protection Spells</h3>
                    <p>This talisman will warn you when danger is lurking around the corner, disorganize your competitors and success will come to you sooner other than later.</p>
                    <Link to={"/spells/protection-spell"} className='btn btn-sm btn-danger rounded-pill mb-2'>Read More ..</Link>
                </div>
            </div>
            <div class="main_heading text-center">
                <h2>Come get an extreme Charm of interest</h2>
            </div>
            
            <p className='wd-75 text-secondary'>Dare to believe in the allure of magic; let its essence infuse every facet of your being. Embrace the enchantments, heed the guidance, and embark on a transformative journey towards an existence illuminated by the radiance of true happiness."</p>
            <div className='d-flex charmed2 flex-wrap gap-4 pb-4'>
                <div className='flex-grow-1'>
                    <h3>Magic Ring</h3>
                    <p className='text-white'>Are you out of each other’s lives, need to stop a divorce or want marriage? You can get you lost lover back by using my powerful Love Spells</p>
                    <Link to={"/charms/magic-ring"} className='btn btn-sm btn-outline-light rounded-pill mb-2'>Read More ..</Link>
                </div>
                <div className='flex-grow-1 bg-secondary'>
                    <h3>Magic Oil</h3>
                    <p className='text-white'>Do you need a spell to attract money, more customers or u are you about to lose your property? Get back your financial freedom using my powerful money spells.</p>
                    <Link to={"/charms/magic-oils"} className='btn btn-sm btn-outline-light rounded-pill mb-2'>Read More ..</Link>
                </div>
                <div className='flex-grow-1 bg-danger'>
                    <h3>Magic Bracelet</h3>
                    <p className='text-white'>Do you want to feel like a goddess, command how attractive you are to the other gender? My beauty spells help in enhancing natural beauty.</p>
                    <Link to={"/charms/magic-bracelet"} className='btn btn-sm btn-outline-light rounded-pill mb-2'>Read More ..</Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default Body