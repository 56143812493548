import React, {useRef, useState} from 'react'
import { Link } from 'react-router-dom'
import "../css/Whatsapp.css"

const Whatsapp = () => {
    const div = useRef()
    const [opened, setOpened] = useState(false)

    const openChat = () => {
        setOpened(()=>!opened)
    }
    return (
        <div>
            <Link className="tsupLink" onClick={openChat}>
                <i className="fab fa-whatsapp" aria-hidden="true"> </i> Whatsapp Us
            </Link>
            <div className="tsupLinkPopup" style={{display: opened === false ? 'none' : 'block'}}>
                <div>
                    <i className="fab fa-whatsapp" aria-hidden="true"></i>
                    <p className='text-white'>Welcome to PowerWitchery Site<br /><span style={{fontSize: '0.875rem'}}>Chat with a customer care</span></p>
                </div>
                <div className="detailed">
                    <img src="images/logo.png" alt="logo" />
                    <Link to={"https://wa.me/256757646679/?text=Hello!"}>
                        <span>Online</span>
                        <h2>Click to chat with us</h2>
                    </Link>
                </div>
                <p className="text-muted">We are online from 8am to 12am</p>
            </div>
        </div>
    )
}

export default Whatsapp