import React from 'react'

const Footer = () => {
  return (
    <div className='bg-dark text-white p-2 text-center'>
        <p className='m-0'>Copyright &copy; {new Date().getFullYear() } powerwitchery.com</p>
    </div>
  )
}

export default Footer