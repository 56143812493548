import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import { useParams } from 'react-router-dom'

const Charms = () => {
    const {name} = useParams()
    const spells = [
        {
            id:1,
            name:'Magic Bracelet',
            description: `
            <h4>Our Bracelets have been used in the following</h4>
            <ul>
                <li>Rituals and Practices</li>
                <li>Cultural and Spiritual Significance</li>
                <li>Control or Domination</li>
                <li>Curses or Malevolence</li>
                <li>Symbolism and Meaning</li>
                <li>Energy and Healing</li>
                <li>Personal Beliefs and Practices</li>
            </ul>
            <p>A magic bracelet, like other magical or mystical items, possess supernatural or spiritual properties. Our bracelets are adorned and worn for various purposes related to spirituality, healing, protection, manifestation, or enhancing specific energies or intentions</p>
            <p></p>
            `,
            image: '/images/witch.jpeg'
        },
        {
            id:2,
            name:'Magic Ring',
            description: `
                <h4>Our Rings have been used in the following</h4>
                <ul>
                    <li>Enhanced Abilities</li>
                    <li>Protection and Defense</li>
                    <li>Control or Domination</li>
                    <li>Curses or Malevolence</li>
                    <li>Symbolism and Significance</li>
                </ul>
                <p>Different cultures and stories attribute diverse capabilities and characteristics to these rings. Come Experience the power of our rings</p>
                <p></p>
                `,
            image: '/images/img2.jpeg'
        },
        {
            id:3,
            name:'Magic Oil',
            description: `
            <h4>Our Oils have been used in the following</h4>
            <ul>
                <li>Rituals and Practices</li>
                <li>Cultural and Spiritual Significance</li>
                <li>Control or Domination</li>
                <li>Curses or Malevolence</li>
            </ul>
            <p>Magic oils are substances used in various spiritual, occult, and magical practices for their perceived mystical properties</p>
            <p>These oils are often created by blending essential oils, herbs, resins, and other ingredients believed to hold specific magical or spiritual attributes. They are used in rituals, spells, ceremonies, anointing, and other magical practices to achieve particular intentions or desired outcomes.</p>
            <p></p>
            `,
            image: '/images/img1.avif'
        }
    ]
    return (
        <div>
            <Top />
            <Navigator />
            <Banner page={name.replaceAll("-"," ")}/>
            <div class="main_heading text-center">
                <h2>We are a call away</h2>
                <p class="large">Always ready to serve you no matter what!</p>
            </div>
            <div className='spells row'>
                <div className='col-md-6'>
                    <p dangerouslySetInnerHTML={{__html: name === 'magic-bracelet' && spells[0].description || name === 'magic-ring' && spells[1].description || name === 'magic-oils' && spells[2].description}}></p>
                </div>
                <div className='col-md-6'>
                    <img src={name === 'magic-bracelet' && spells[0].image || name === 'magic-ring' && spells[1].image || name === 'magic-oils' && spells[2].image} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Charms