import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import { useParams } from 'react-router-dom'
import "../css/Spells.css"
//name.replaceAll("-"," ")

const Spells = () => {
    const {name} = useParams()
    const spells = [
        {
            id:1,
            name:'Love Spell',
            description: `
            <h4>Our Love Spells have been used in</h4>
            <ul>
                <li>Rituals and Practices</li>
                <li>Cultural and Spiritual Significance</li>
                <li>Control or Domination</li>
                <li>Chants</li>
            </ul>
            <p>Our spells attract love into your life, while others focus on strengthening existing relationships</p>
            <p>the decision of whether or not to engage in love spells is a personal one. If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/witch.jpeg'
        },
        {
            id:2,
            name:'Protection Spell',
            description: `
            <h4>Our Protection Spells have been used in</h4>
            <ul>
                <li>Cleansing: These spells remove negative energy from your space or aura, promoting peace and positivity.</li>
                <li>Shielding: They create a protective barrier around you, deflecting negativity and harmful intentions</li>
                <li>Banishing: Used to expel negative forces, entities, or unwanted influences from your life.</li>
                <li>Binding: Restricts the influence of negative individuals or situations, preventing them from causing harm.</li>
            </ul>
            <p>Protection spells are a powerful tool for safeguarding yourself and your loved ones from negativity, harm, and danger. They come in many forms and can be tailored to specific needs</p>
            <p>If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/img2.jpeg'
        },
        {
            id:3,
            name:'Money Spell',
            description: `
            <h4>Our Money Spells have been used in</h4>
            <ul>
                <li>Attraction: These spells focus on drawing money and financial opportunities towards you. This can involve visualization, affirmations, or working with specific symbols or ingredients.</li>
                <li>Prosperity: Aim to increase your overall abundance and flow of wealth. They may involve rituals for abundance, gratitude practices, or offerings to deities associated with prosperity.</li>
                <li>Debt Clearing: Specifically target eliminating existing financial burdens and debts. This can involve burning symbolic representations of debt or rituals involving clearing negative energy.</li>
                <li>Career Success: Focus on attracting opportunities for advancement, promotion, and financial success in your career. This can involve chants for career growth, offerings to deities of work and success, or visualization exercises.</li>
            </ul>
            <p>Money spells are rituals or practices aimed at attracting wealth, prosperity, and financial abundance into your life. </p>
            <p>If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/img1.avif'
        }
    ]
    return (
        <div>
            <Top />
            <Navigator />
            <Banner page={name.replaceAll("-"," ")}/>
            <div class="main_heading text-center">
                <h2>We are a call away</h2>
                <p class="large">Always ready to serve you no matter what!</p>
            </div>
            <div className='spells row'>
                <div className='col-md-6'>
                    <p dangerouslySetInnerHTML={{__html: name === 'love-spell' && spells[0].description || name === 'protection-spell' && spells[1].description || name === 'money-spell' && spells[2].description}}></p>
                </div>
                <div className='col-md-6'>
                    <img src={name === 'love-spell' && spells[0].image || name === 'protection-spell' && spells[1].image || name === 'money-spell' && spells[2].image} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Spells