import React from 'react'
import { Link } from 'react-router-dom'
import "../css/Top.css"

const Top = () => {

    const handleMailClick = () => {
        const recipientEmail = 'recipient@example.com';
        const subject = encodeURIComponent('Appointment Request');
        const selectedDate = prompt('Please enter the desired appointment date (YYYY-MM-DD):');
    
        if (selectedDate) {
          const body = encodeURIComponent(`Hello, I would like to schedule an appointment for ${selectedDate}.`);
          window.location.href = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;
        }
    };
    
    return (
        <div className='row align-items-center bg-danger top'>
            <div className='col-md-8'>
                <ul class="list-inline upper d-flex gap-4 m-0 pt-2 pb-2">
                    <li> <span class="topbar-label"><i className="fa fa-home"></i></span> <span class="topbar-hightlight">Location of the office here</span> </li>
                    <li> <span class="topbar-label"><i className="fa fa-envelope"></i></span> <span class="topbar-hightlight"><a href="mailto:rash@powerwitchery.com">rash@powerwitchery.com</a></span> </li>
                </ul>
            </div>
            <div className='col-md-4 d-flex justify-content-end'>
                <ul class="list-inline d-flex m-0 gap-3 pt-2 pb-2">
                    <li><Link className="fab fa-facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"></Link></li>
                    <li><Link className="fab fa-twitter" href="https://twitter.com" title="Twitter" target="_blank"></Link></li>
                    <li><Link className="fab fa-linkedin" href="https://www.linkedin.com" title="LinkedIn" target="_blank"></Link></li>
                    <li><Link className="fab fa-instagram" href="https://www.instagram.com" title="Instagram" target="_blank"></Link></li>
                </ul>
                <div className='last pt-2 pb-2 ms-3'>
                    <Link onClick={handleMailClick}>Make an appointment</Link>
                </div>
            </div>
        </div>
    )
}

export default Top