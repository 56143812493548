import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Entry from './components/Entry';
import { ActivePageProvider } from './components/ActivePageContext';
import About from './components/About';
import Contact from './components/Contact';
import Spells from './components/Spells';
import Charms from './components/Charms';

function App() {
  return (
    <div className="App">
      <ActivePageProvider>
        <Routes>
          <Route path="/" element={<Entry />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/contact-us" element={<Contact />}/>
          <Route path="/spells/:name" element={<Spells />}/>
          <Route path="/charms/:name" element={<Charms />}/>
        </Routes>
      </ActivePageProvider>
    </div>
  );
}

export default App;
